import { Box, Flex } from "grid-styled";
import { useTranslation } from "react-i18next";
import { themeGet } from "styled-system";
import system from "system-components";

import { StyledFontAwesomeIcon } from "../../../partials/icons";

import Modal from "pstat-design-system/modal";
import { Button } from "pstat-design-system/shared/Button";
import { H2 } from "pstat-design-system/text/headings";
import { layer } from "pstat-design-system/utils";


export const CloseMenuBox = system({
  is: Box
}).extend`
  &:focus {
    outline: 0;
  }
`;

export const FocusBorder = system({
  border: "dashed 1px",
  borderColor: "transparent",
  borderRadius: 1,
  p: 1
}).extend`
  &:focus {
    outline: 0;
  }
`;

export const CloseMenuFocusBorder = FocusBorder.extend`
  ${CloseMenuBox}:focus > & {
    border: dashed 1px;
  }
`;

export const FiltersPositioner = system({
  bg: "nav.95",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  position: "fixed",
  width: "30%",
  top: 0,
  bottom: 0,
  right: 0,
  layer: "activeFloatingUi"
}).extend`
  ${layer};
  box-shadow: -2px 2px 0px 0px ${themeGet("colors.nav.90")};
  min-height: 100%;
`;

export const FiltersTitle = props => {
  const handleKeyDown = event => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      props.hideFilters();
    }
  };
  const { t } = useTranslation();
  return (
    <Flex justify="space-between" p={2} bg="nav.100">
      <Box>
        <H2 size="normal" color="nav.0">
          {t("documentSearch.searchFilters.filtersTitle")}
        </H2>
      </Box>
      <CloseMenuBox
        onClick={props.hideFilters}
        onKeyDown={handleKeyDown}
        tabIndex="0"
      >
        <CloseMenuFocusBorder tabIndex="-1">
          <StyledFontAwesomeIcon icon={["far", "times"]} />
        </CloseMenuFocusBorder>
      </CloseMenuBox>
    </Flex>
  );
};

export const StyledFooter = system({
  is: "footer", // For styling when full screen mode on
  bg: "nav.100",
  px: 2,
  py: 2
});

export const FiltersFooter = props => {
  return (
    <StyledFooter mt={1}>
      <Flex justify="space-between">
        <Box>
          <Button buttonStyle="secondary" onClick={props.onSearch}>
            Search
          </Button>
        </Box>
        <Box>
          {/* TODO add the key handler to these as well */}
          <Button buttonStyle="tertiary" onClick={props.onClear}>
            Clear
          </Button>
        </Box>
      </Flex>
    </StyledFooter>
  );
};

export const MobileFiltersModal = props => (
  <Modal
    contentLabel="Search Filters"
    isOpen={props.isShown}
    onRequestClose={props.onClose}
    onAfterOpen={props.onAfterOpen}
    contentRef={props.contentRef}
  >
    {props.children}
  </Modal>
);

export const FullHeightFlex = system({
  is: Flex,
  height: "100%"
});

export const OverflowFlex = system({
  is: Flex
}).extend`
  overflow: auto;
`;
