import { Box, Flex } from "grid-styled";
import { Component, Fragment } from "react";
import { useTranslation } from "react-i18next";
import Media from "react-media";
import { QueryRenderer, fetchQuery } from "react-relay";
import styled from "styled-components";
import { themeGet } from "styled-system";

import { SearchContentMobile } from "pstat-anywhere/components/document_search/MobileSearch/MobileSearch";
import SearchFilters from "pstat-anywhere/components/document_search/SearchFilters/SearchFilters";
import SearchFiltersQuery from "pstat-anywhere/components/document_search/SearchFilters/SearchFiltersQuery";
import SearchResultsCSVQuery from "pstat-anywhere/components/document_search/SearchResults/SearchResultsCSVQuery";
import SearchResultsQuery from "pstat-anywhere/components/document_search/SearchResults/SearchResultsQuery";
import { ContentContainer } from "pstat-anywhere/components/pages";
import Export from "pstat-anywhere/components/partials/export/Export";
import { withLabelContext } from "pstat-anywhere/context_providers/LabelContext";
import { environment } from "pstat-anywhere/relay";
import theme from "pstat-anywhere/themes/policystat/theme";
import {
  TriggerEventOnMount,
  triggerBasicSearchEvent
} from "pstat-anywhere/utils/googleTagManager";
import { useAdoptedMapping } from "pstat-anywhere/utils/hooks/useAdoptedMapping";
import titleCase from "pstat-anywhere/utils/titleCase";
import { getRootUrl } from "pstat-anywhere/utils/urls";
import { Badge } from "pstat-design-system/Badge";
import Card from "pstat-design-system/Card";
import LoadingSpinner from "pstat-design-system/LoadingSpinner";
import Pagination from "pstat-design-system/Pagination";
import { SearchShareButton } from "pstat-design-system/ShareButton";
import { HorizontalScrollTable } from "pstat-design-system/tables";
import { Text } from "pstat-design-system/text";
import { H1 } from "pstat-design-system/text/headings";
import { fontFamily } from "pstat-design-system/utils";

const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${themeGet("space.4")}px;
`;

const FontContainer = styled.div`
  ${fontFamily};
`;

const LandingMessage = props => {
  return (
    <FontContainer>
      <Card>{props.children}</Card>
    </FontContainer>
  );
};

export const SearchResultsBadge = props => {
  const singular = props.totalNumberOfResults === 1;
  return (
    <Badge>
      {props.totalNumberOfResults} {singular ? "result" : "results"}
    </Badge>
  );
};

export const SearchResults = props => {
  const { t } = useTranslation();
  const searchVariables = {
    q: props.query,
    titleOnly: props.titleOnly,
    authors: props.authors,
    categories: props.categories,
    references: props.references,
    first: hitsPerPage,
    after: props.afterCursor,
    sort: props.sort
  };

  const {
    adoptedMapping,
    onChangeAdoptedDocument,
    constructDocumentsOfTemplate
  } = useAdoptedMapping();

  const buildPoliciesForRendering = searchResults => {
    return searchResults.edges.map(({ node }) => ({
      ...{
        ...(adoptedMapping[node.pk] || node),
        originPk: node.pk,
        documentsOfTemplate: constructDocumentsOfTemplate(node)
      },
      onChangeAdoptedDocument
    }));
  };

  return (
    <Fragment>
      <QueryRenderer
        environment={environment}
        query={SearchResultsQuery}
        variables={searchVariables}
        render={obj => {
          const queryProps = obj.props;
          // TODO decide how to handle errors
          if (queryProps && queryProps.search) {
            const documents = queryProps.search.searchResults;
            const { totalCount } = documents;
            props.onTotalCountUpdate(totalCount);
            props.handleGuestShareTokenUpdate(
              queryProps.search.guestAccessToken
            );
            const policies = buildPoliciesForRendering(documents);
            return (
              <TriggerEventOnMount
                triggerEvent={() => {
                  if (!props.afterCursor) {
                    triggerBasicSearchEvent(
                      totalCount,
                      window.location.search.substring(1)
                    );
                  }
                }}
              >
                <Media query={{ minWidth: theme.breakpoints[1] }}>
                  {isDesktop => (
                    <div>
                      {policies.length ? (
                        <Card borderRadius={isDesktop ? false : 0}>
                          <HorizontalScrollTable
                            tableType={isDesktop ? "search" : "search_mobile"}
                            policies={policies}
                            numberToDisplay={hitsPerPage}
                            borderRadius="0"
                            sort={props.sort}
                            onSortChange={props.onSortChange}
                            noSort={!isDesktop || (!props.sort && props.query)}
                            searchQuery={props.query}
                            totalNumberOfResults={totalCount}
                            afterCursor={props.afterCursor}
                            hitsPerPage={hitsPerPage}
                            hasFrozenColumn={false}
                          />
                        </Card>
                      ) : (
                        <LandingMessage>
                          {t("documentSearch.searchResults.noResults", {
                            query: props.query
                          })}
                        </LandingMessage>
                      )}
                    </div>
                  )}
                </Media>
              </TriggerEventOnMount>
            );
          }
          return (
            <Flex justify="center" align="center" mt={10}>
              <Box>
                <LoadingSpinner size={theme.space[7]} />
              </Box>
            </Flex>
          );
        }}
      />
      <CenterContainer>
        <Pagination
          totalNumberOfResults={props.totalNumberOfResults}
          hitsPerPage={hitsPerPage}
          afterCursor={props.afterCursor}
          onPageChange={props.onPageChange}
        />
      </CenterContainer>
    </Fragment>
  );
};

const hitsPerPage = 20;

const SearchInfoContainer = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  margin-top: ${themeGet("space.6")}px;
  margin-bottom: ${themeGet("space.2")}px;
`;

export const CSVIconContainer = styled(Flex)`
  padding: ${themeGet("space.1")}px;
  justify-content: center;
  align-items: center;
`;

export const SearchContentDesktop = ({
  after,
  totalNumberOfResults,
  titleOnly,
  authors,
  categories,
  references,
  expanded,
  q,
  onTotalCountUpdate,
  onExpandedFilterChange,
  onFiltersChange,
  sort,
  onSortChange,
  onPageChange,
  csvQuery,
  environment,
  labels,
  filterOptions,
  fetchFilterOptions,
  handleGuestShareTokenUpdate,
  guestAccessToken
}) => {
  const { t } = useTranslation();
  const { documentLabelPlural } = labels;

  const filtersUpdateKey = `${titleOnly}${authors.sort()}${categories.sort()}${references.sort()}`;
  return (
    <Fragment>
      <SearchInfoContainer>
        <Flex align="center">
          <H1 color="nav.0" display="inline-block">
            {titleCase(
              t("inputs.searchInput.placeholder", { documentLabelPlural })
            )}
          </H1>
          <SearchResultsBadge totalNumberOfResults={totalNumberOfResults} />
          <Text color="nav.25">in</Text>
          <SearchFilters
            key={filtersUpdateKey}
            expanded={expanded}
            filters={{ titleOnly, authors, categories, references }}
            onExpandedFilterChange={onExpandedFilterChange}
            onFiltersChange={onFiltersChange}
            environment={environment}
            filterOptions={filterOptions}
            fetchFilterOptions={fetchFilterOptions}
          />
        </Flex>
        <Flex>
          <CSVIconContainer>
            <Export
              queryName="searchCsv"
              csvQuery={csvQuery}
              csvQueryArgs={{
                titleOnly,
                authors,
                categories,
                references,
                q
              }}
            />
          </CSVIconContainer>
          {guestAccessToken && <SearchShareButton token={guestAccessToken} />}
        </Flex>
      </SearchInfoContainer>
      <SearchResults
        query={q}
        afterCursor={after}
        titleOnly={titleOnly}
        authors={authors}
        categories={categories}
        references={references}
        totalNumberOfResults={totalNumberOfResults}
        onTotalCountUpdate={onTotalCountUpdate}
        handleGuestShareTokenUpdate={handleGuestShareTokenUpdate}
        sort={sort}
        onSortChange={onSortChange}
        onPageChange={onPageChange}
      />
    </Fragment>
  );
};

export const RelativePositioner = styled.div`
  position: relative;
`;

class _SearchResultsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      totalNumberOfResults: 0,
      filterOptions: null,
      loadingFilterOptions: false,
      guestAccessToken: null
    };
  }

  handleTotalCountUpdate = totalCount => {
    if (this.state.totalNumberOfResults !== totalCount) {
      this.setState({ totalNumberOfResults: totalCount });
    }
  };

  handleExpandedFilterChange = expanded => {
    const queryParams = { ...this.props.location.query };
    delete queryParams.expanded;

    if (expanded) {
      queryParams.expanded = expanded;
    }

    const to = {
      pathname: `${getRootUrl()}/search/`,
      query: queryParams
    };
    this.props.router.push(to);
  };

  handleFiltersChange = params => {
    const { expanded, titleOnly, authors, categories, references } = params;

    const queryParams = { ...this.props.location.query };
    delete queryParams.after;
    delete queryParams.expanded;
    delete queryParams.titleOnly;
    delete queryParams.author;
    delete queryParams.category;
    delete queryParams.reference;

    if (expanded) {
      queryParams.expanded = expanded;
    }
    if (titleOnly) {
      queryParams.titleOnly = true;
    }
    if (authors) {
      queryParams.author = authors;
    }
    if (categories) {
      queryParams.category = categories;
    }
    if (references) {
      queryParams.reference = references;
    }

    const to = {
      pathname: `${getRootUrl()}/search/`,
      query: queryParams
    };
    this.props.router.push(to);
  };

  handleGuestShareTokenUpdate = token => {
    if (token && this.state.guestAccessToken !== token) {
      this.setState({ guestAccessToken: token });
    }
  };

  handleSortChange = sort => {
    const queryParams = { ...this.props.location.query };
    queryParams.sort = sort;
    const to = {
      pathname: `${getRootUrl()}/search/`,
      query: queryParams
    };
    this.props.router.push(to);
  };

  handlePageChange = afterCursor => {
    const queryParams = { ...this.props.location.query };
    delete queryParams.after;

    if (afterCursor) {
      queryParams.after = afterCursor;
    }
    const to = {
      pathname: `${getRootUrl()}/search/`,
      query: queryParams
    };
    this.props.router.push(to);
  };

  convertFiltersToNumbers = filters => {
    if (!Array.isArray(filters)) {
      filters = [filters];
    }
    // if the pk is an integer, then return the converted pk
    return filters
      .filter(stringParam => !isNaN(parseInt(stringParam, 10)))
      .map(stringPk => parseInt(stringPk, 10));
  };

  fetchFilterOptions = () => {
    if (this.state.loadingFilterOptions) {
      return;
    }
    fetchQuery(environment, SearchFiltersQuery, {}).then(data => {
      if (!data) {
        return;
      }
      const {
        authors,
        categoriesWithActiveDocuments,
        tagsOnActiveDocuments
      } = data;
      this.setState({
        filterOptions: {
          authors,
          categories: categoriesWithActiveDocuments,
          tags: tagsOnActiveDocuments
        },
        loadingFilterOptions: false
      });
    });
    this.setState({ loadingFilterOptions: true });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.query.q !== prevProps.location.query.q) {
      delete this.props.location.query.after;

      const queryParams = { ...this.props.location.query };
      const to = {
        pathname: `${getRootUrl()}/search/`,
        query: queryParams
      };
      this.props.router.push(to);
    }
  }

  render() {
    const { author, category, reference } = this.props.location.query;
    let authors = [];
    let categories = [];
    let references = [];
    if (author) {
      authors = this.convertFiltersToNumbers(author);
    }
    if (category) {
      categories = this.convertFiltersToNumbers(category);
    }
    if (reference) {
      references = this.convertFiltersToNumbers(reference);
    }
    const { totalNumberOfResults } = this.state;
    const { after, expanded, q, sort, titleOnly } = this.props.location.query;
    const { labels } = this.props;

    const commonProps = {
      after,
      totalNumberOfResults,
      titleOnly,
      authors,
      categories,
      references,
      expanded,
      q,
      first: hitsPerPage,
      onExpandedFilterChange: this.handleExpandedFilterChange,
      onFiltersChange: this.handleFiltersChange,
      onTotalCountUpdate: this.handleTotalCountUpdate,
      onPageChange: this.handlePageChange,
      environment,
      labels,
      filterOptions: this.state.filterOptions,
      fetchFilterOptions: this.fetchFilterOptions,
      handleGuestShareTokenUpdate: this.handleGuestShareTokenUpdate,
      guestAccessToken: this.state.guestAccessToken
    };
    return (
      <RelativePositioner>
        <ContentContainer>
          <Media query={{ minWidth: theme.breakpoints[1] }}>
            {matches =>
              matches ? (
                <SearchContentDesktop
                  {...commonProps}
                  sort={sort}
                  onSortChange={this.handleSortChange}
                  csvQuery={SearchResultsCSVQuery}
                />
              ) : (
                <SearchContentMobile
                  {...commonProps}
                  router={this.props.router}
                  csvQuery={SearchResultsCSVQuery}
                />
              )
            }
          </Media>
        </ContentContainer>
      </RelativePositioner>
    );
  }
}

export const SearchResultsPage = withLabelContext(_SearchResultsPage);
