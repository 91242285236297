import { Box } from "grid-styled";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import system from "system-components";

import { AccessibleBlocsForAdoptedDocuments } from "pstat-anywhere/components/document_control/view_policy/policy_properties/AccessibleBlocsForAdoptedDocuments";
import { RegulationItem } from "pstat-anywhere/components/document_control/view_policy/standards_and_regulations/StandardsAndRegulationsPanel";
import Properties, {
  ApprovedDate,
  AuthorContainer,
  AuthorSearchLink,
  CategoryContainer,
  CategorySearchLink,
  DateKeyValueRow,
  KeyValueRow,
  ReferencesContainer,
  RevisionDate,
  TagSearchLink
} from "pstat-anywhere/components/view/Properties";
import { withLabelContext } from "pstat-anywhere/context_providers/LabelContext";
import { withTenantContext } from "pstat-anywhere/context_providers/TenantContext";
import RouterFocusLink from "pstat-design-system/RouterFocusLink";
import { Text } from "pstat-design-system/text/paragraph";
import Tooltip from "pstat-design-system/Tooltip";

export const NUM_ITEMS_TO_DISPLAY_IN_HEADER = 3;

export const PreviewValueSpan = system({
  is: Text,
  display: "inline-block",
  p: 1
});

const ApplicabilityName = ({
  showLinkToApplicabilitySection,
  name,
  pathname,
  hash
}) => {
  return (
    <Fragment>
      {showLinkToApplicabilitySection ? (
        <RouterFocusLink
          to={{ pathname, hash }}
          data-tip={true}
          data-for="applicability-tenants-tooltip"
        >
          {name}
        </RouterFocusLink>
      ) : (
        <Box width={1 / 2} py={1} px={1}>
          <Text aria-hidden="true">{name}</Text>
        </Box>
      )}
    </Fragment>
  );
};

const ApplicabilitySectionLink = ({
  applicability,
  documentsToDisplay,
  systemDocumentTemplate,
  showLinkToApplicabilitySection = true
}) => {
  const { name: blocName, pk: blocPk } = applicability;
  const pathname = window.location.pathname;
  const hash = "#applicability-panel";
  return (
    <Fragment>
      {documentsToDisplay.length > 0 || systemDocumentTemplate ? (
        <AccessibleBlocsForAdoptedDocuments
          title={blocName}
          items={documentsToDisplay}
          blocPk={blocPk}
          systemDocumentTemplate={systemDocumentTemplate}
        />
      ) : (
        <ApplicabilityName
          showLinkToApplicabilitySection={showLinkToApplicabilitySection}
          name={blocName}
          pathname={pathname}
          hash={hash}
        />
      )}
    </Fragment>
  );
};

const MoreItemsMoveToPanelLink = ({ moreCounter, panelHash }) => {
  const { t } = useTranslation();
  return (
    <RouterFocusLink
      pb={1}
      to={{
        pathname: window.location.pathname,
        hash: panelHash
      }}
    >
      {t("nav.primary.manage.more.counter", { count: moreCounter })}
    </RouterFocusLink>
  );
};

const Tag = tag => {
  const { pk, name, isPreview, isLastItem } = tag;
  const customName = `${name}${!isLastItem ? "," : ""}`;
  return (
    <Fragment>
      {isPreview ? (
        <PreviewValueSpan>{customName}</PreviewValueSpan>
      ) : (
        <TagSearchLink pk={pk} text={customName} />
      )}
    </Fragment>
  );
};

const ListItems = ({ items, ItemComponent, moreHash }) => {
  return items.map((item, index) => {
    const isLastItem = index === items.length - 1;
    if (index < NUM_ITEMS_TO_DISPLAY_IN_HEADER) {
      return <ItemComponent {...item} newLine={true} isLastItem={isLastItem} />;
    } else if (index === NUM_ITEMS_TO_DISPLAY_IN_HEADER) {
      return (
        <MoreItemsMoveToPanelLink
          moreCounter={items.length - NUM_ITEMS_TO_DISPLAY_IN_HEADER}
          panelHash={moreHash}
        />
      );
    }
    return null;
  });
};

const Dates = ({
  labels,
  tenant,
  effectiveDate,
  approvalDate,
  expirationDate,
  revisionDate,
  originationDate
}) => {
  const { documentSettings } = tenant;
  return (
    <Fragment>
      {documentSettings.originationDateShow && (
        <DateKeyValueRow
          id="origination_date"
          label={labels.originationDateLabel}
          value={originationDate}
        />
      )}
      <ApprovedDate
        documentSettings={documentSettings}
        labels={labels}
        date={approvalDate}
      />
      {tenant.hasScheduledEffectiveDateEnabled &&
        documentSettings.effectiveDateShow && (
          <DateKeyValueRow
            id="effective_date"
            label={labels.effectiveDateLabel}
            value={effectiveDate}
          />
        )}
      <RevisionDate
        documentSettings={documentSettings}
        labels={labels}
        date={revisionDate}
      />
      {documentSettings.expirationDateShow && (
        <DateKeyValueRow
          id="expiration_date"
          label={labels.expirationDateLabel}
          value={expirationDate}
        />
      )}
    </Fragment>
  );
};

const CenteredDiv = system({
  textAlign: "center"
});

export const ApplicabilityTenantsTootip = props => {
  const tenantList = props.bloc.tenants.map(tenant => (
    <CenteredDiv key={tenant.pk}>{tenant.name}</CenteredDiv>
  ));
  return <Tooltip name="applicability-tenants-tooltip">{tenantList}</Tooltip>;
};

const Ownership = ({
  labels,
  documentSettings,
  author,
  category,
  bloc,
  tenant,
  tags,
  isPreview,
  regulations,
  documentsToDisplay,
  systemDocumentTemplate,
  isTemplate,
  showLinkToApplicabilitySection
}) => {
  const showApplicability = tenant.showApplicability || isTemplate;
  const { t } = useTranslation();
  return (
    <Fragment>
      <AuthorContainer documentSettings={documentSettings} labels={labels}>
        {isPreview ? (
          <PreviewValueSpan>
            {author.firstName} {author.lastName}
          </PreviewValueSpan>
        ) : (
          <AuthorSearchLink author={author} />
        )}
      </AuthorContainer>
      <CategoryContainer documentSettings={documentSettings} labels={labels}>
        {isPreview ? (
          <PreviewValueSpan>{category.name}</PreviewValueSpan>
        ) : (
          <CategorySearchLink category={category} />
        )}
      </CategoryContainer>
      {showApplicability && (
        <KeyValueRow id="applicability" label="Applicability">
          {isPreview ? (
            <PreviewValueSpan>{bloc.name}</PreviewValueSpan>
          ) : (
            <ApplicabilitySectionLink
              applicability={bloc}
              documentsToDisplay={documentsToDisplay}
              systemDocumentTemplate={systemDocumentTemplate}
              showLinkToApplicabilitySection={showLinkToApplicabilitySection}
            />
          )}
        </KeyValueRow>
      )}
      <ReferencesContainer
        tags={tags}
        documentSettings={documentSettings}
        labels={labels}
      >
        <ListItems
          items={tags.map(tag => ({ ...tag, isPreview }))}
          ItemComponent={Tag}
          moreHash="#references"
        />
      </ReferencesContainer>
      {regulations && (
        <Fragment>
          {regulations.length > 0 && (
            <KeyValueRow
              id="standards-and-regulations"
              label={t("nav.primary.manage.standardsAndRegs")}
            >
              <ListItems
                items={regulations}
                ItemComponent={RegulationItem}
                moreHash="#standards-and-regulations-panel"
              />
            </KeyValueRow>
          )}
        </Fragment>
      )}
      <ApplicabilityTenantsTootip bloc={bloc} />
    </Fragment>
  );
};

const PolicyProperties = ({
  effectiveDate,
  approvalDate,
  expirationDate,
  revisionDate,
  originationDate,
  author,
  category,
  bloc,
  tags,
  labels,
  tenant,
  headerImageUrl,
  regulations,
  isPreview = false,
  documentsToTemplate = [],
  systemDocumentTemplate,
  isTemplate = false,
  showLinkToApplicabilitySection = true
}) => (
  <Properties
    headerImageUrl={headerImageUrl}
    renderDates={() => (
      <Dates
        labels={labels}
        tenant={tenant}
        effectiveDate={effectiveDate}
        approvalDate={approvalDate}
        expirationDate={expirationDate}
        revisionDate={revisionDate}
        originationDate={originationDate}
      />
    )}
    renderProperties={() => (
      <Ownership
        labels={labels}
        documentSettings={tenant.documentSettings}
        author={author}
        category={category}
        bloc={bloc}
        tenant={tenant}
        tags={tags}
        isPreview={isPreview}
        regulations={regulations}
        documentsToDisplay={documentsToTemplate}
        systemDocumentTemplate={systemDocumentTemplate}
        isTemplate={isTemplate}
        showLinkToApplicabilitySection={showLinkToApplicabilitySection}
      />
    )}
  />
);

export default withTenantContext(withLabelContext(PolicyProperties));
