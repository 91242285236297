import { useState } from "react";

export const useAdoptedMapping = () => {
  const [adoptedMapping, setAdoptedMapping] = useState({});

  const constructDocumentsOfTemplate = node => {
    const { documentsOfTemplate } = node;
    const { documents } = documentsOfTemplate;

    if (documents.length === 0) {
      return documentsOfTemplate;
    }

    const documentsWithAlreadySelectedDocument = [node, ...documents];

    const selectedDocument = adoptedMapping[node.pk] || node;
    return {
      ...documentsOfTemplate,
      documents: documentsWithAlreadySelectedDocument.filter(
        ({ pk }) => pk !== selectedDocument.pk
      )
    };
  };

  const onChangeAdoptedDocument = (updatedDocs, documentPk) => {
    setAdoptedMapping({
      ...adoptedMapping,
      [documentPk]: updatedDocs[0]
    });
  };

  return {
    adoptedMapping,
    setAdoptedMapping,
    constructDocumentsOfTemplate,
    onChangeAdoptedDocument
  };
};
