import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { QueryRenderer } from "react-relay";

import DocumentsForReviewCSVQuery from "./graphql/DocumentsForReviewCSVQuery";
import DocumentsToReviewQuery from "./graphql/DocumentsForReviewQuery";
import useDocumentsToReveiwReport from "./hooks";

import { SearchBarAndFilters } from "pstat-anywhere/components/document_reports/expiring/DocumentsForReviewFilters";
import { shouldShowReportToggle } from "pstat-anywhere/components/document_reports/expiring/utils";
import {
  AdvancedReportPage,
  DefaultActionPanel
} from "pstat-anywhere/components/pages/reports";
import { PolicyTypeSelector } from "pstat-anywhere/components/pages/reports/components/PolicyTypeSelector";
import { Bucket } from "pstat-anywhere/components/reports/Buckets";
import { withLabelContext } from "pstat-anywhere/context_providers/LabelContext";
import { environment } from "pstat-anywhere/relay";
import { useAdoptedMapping } from "pstat-anywhere/utils/hooks/useAdoptedMapping";
import { ErrorCheckWrapper } from "pstat-anywhere/utils/routes";
import { InfoBanner } from "pstat-design-system/banner";


export const Buckets = ({ bucketsConfig }) => {
  const bucketItems = bucketsConfig.map(({ label, count, color, key }) => (
    <Bucket label={label} count={count} color={color} key={key} />
  ));
  return <Fragment>{bucketItems}</Fragment>;
};

export const getBucketsConfig = (t, isTemplateView, buckets) => {
  if (isTemplateView) {
    return [
      {
        key: 0,
        label: t("documentReports.waitingLessThanBucket", { count: 30 }),
        count: buckets?.["30"],
        color: "buckets.ok"
      },
      {
        key: 1,
        label: t("documentReports.waitingLessThanBucket", { count: 60 }),
        count: buckets?.["60"],
        color: "buckets.warning"
      },
      {
        key: 2,
        label: t("documentReports.waitingLessThanBucket", { count: 90 }),
        count: buckets?.["90"],
        color: "buckets.warning"
      },
      {
        key: 3,
        label: t("documentReports.waitingGreaterThanBucket", { count: 90 }),
        count: buckets?.["old"],
        color: "buckets.danger"
      }
    ];
  }
  const lastStepKey = buckets ? Object.keys(buckets)[3] : null;

  return [
    {
      key: 0,
      label: t("documentReports.pastDue"),
      count: buckets?.["0"],
      color: "buckets.danger"
    },
    {
      key: 1,
      label: t("documentReports.lessThanBucket", { count: 30 }),
      count: buckets?.["30"],
      color: "buckets.warning"
    },
    {
      key: 2,
      label: t("documentReports.lessThanBucket", { count: 60 }),
      count: buckets?.["60"],
      color: "buckets.ok"
    },
    {
      key: 3,
      label: t("documentReports.lessThanBucket", {
        count: lastStepKey ? lastStepKey : ""
      }),
      count: buckets?.[lastStepKey],
      color: "buckets.ok"
    },
    {
      key: 4,
      label: t("documentReports.greaterThanBucket", {
        count: lastStepKey ? lastStepKey : ""
      }),
      count: buckets?.not_expiring,
      color: "buckets.neutral"
    }
  ];
};

function DocumentsToReviewReport({ route, router, location, labels }) {
  const { t } = useTranslation();
  const {
    variables,
    handleBulkReview,
    loading,
    isTemplateView
  } = useDocumentsToReveiwReport({
    location,
    router,
    route,
    labels,
    t
  });
  const {
    justMine,
    includePending,
    categories,
    daysUntilExpiration,
    reviewType
  } = variables;
  const categoriesArg = categories;
  const baseUrl = isTemplateView
    ? "policy_reports/expiring/template"
    : "policy_reports/expiring";
  const tabs = [
    {
      id: isTemplateView ? "expiring_templates_all" : "expiring_all",
      textKey: "documentReports.expiring.tabs.everyone",
      path: baseUrl
    },
    {
      id: isTemplateView ? "expiring_templates_mine" : "expiring_mine",
      textKey: "documentReports.expiring.tabs.mine",
      path: `${baseUrl}/mine`
    }
  ];
  const isCheckboxDisabled = rowData => {
    if (rowData.cannotBulkReviewReason === "HAS_DRAFT") {
      return "documentReports.expiring.cannotBulkReview.hasDraft";
    }
    if (rowData.cannotBulkReviewReason === "INACTIVE_AUTHOR") {
      return "documentReports.expiring.cannotBulkReview.inactiveAuthor";
    }
    if (rowData.cannotBulkReviewReason === "SPECIFIC_EXPIRATION_DATE") {
      return "documentReports.expiring.cannotBulkReview.specificExpirationDate";
    }
  };
  const getExpiringTemplatesTable = () => "expiring_templates";
  const getExpiringDocumentsTable = () =>
    includePending ? "expiring_with_pending" : "expiring";
  const getExpringDocumentsWithBulReviewTable = () =>
    includePending
      ? "expiring_with_bulk_review_pending"
      : "expiring_with_bulk_review";

  const getExpiringTable = showBulkReview => {
    if (isTemplateView) {
      return getExpiringTemplatesTable();
    }
    if (showBulkReview) {
      return getExpringDocumentsWithBulReviewTable();
    }
    return getExpiringDocumentsTable();
  };
  const {
    adoptedMapping,
    onChangeAdoptedDocument,
    constructDocumentsOfTemplate
  } = useAdoptedMapping();

  return (
    <QueryRenderer
      environment={environment}
      variables={variables}
      query={DocumentsToReviewQuery}
      render={({ props }) => {
        const tableType = getExpiringTable(
          props?.documentsToReview?.showBulkReview
        );
        const documentsToReview = props?.documentsToReview || {};
        const {
          buckets,
          categories,
          documents,
          rssFeedUrl,
          counts
        } = documentsToReview;
        const { timeCount, templateCount } = counts || {};
        const showReportToggle = shouldShowReportToggle(counts);
        const headerText = showReportToggle
          ? t("documentReports.expiring.headerWithoutTypeDocuments")
          : t("documentReports.expiring.header", {
              documentLabelPlural: labels.documentLabelPlural
            });
        const templateSelectorTitle = t(
          "documentReports.expiring.documentTypeSelector.templateTitle",
          {
            documentLabelPlural: labels.documentLabelPlural
          }
        );
        const currentPolicyTypeTitle = isTemplateView
          ? templateSelectorTitle
          : labels.documentLabelPlural;
        const urlExpiring = "/policy_reports/expiring";
        const urlExpiringTemplate = "/policy_reports/expiring/template";
        const hasTemplateInDocuments =
          documents &&
          documents.edges
            .map(({ node }) => node.isTemplate)
            .some(isTemplate => isTemplate);
        const PrefixHeaderComponent = showReportToggle
          ? () => (
              <PolicyTypeSelector
                policyTypes={[
                  {
                    key: "timeCount",
                    counter: timeCount,
                    name: labels.documentLabelPlural,
                    url: isTemplateView ? urlExpiring : null,
                    to: isTemplateView ? null : urlExpiring
                  },
                  {
                    key: "templateCount",
                    counter: templateCount,
                    name: templateSelectorTitle,
                    url: isTemplateView ? null : urlExpiringTemplate,
                    to: isTemplateView ? urlExpiringTemplate : null
                  }
                ]}
                title={currentPolicyTypeTitle}
              />
            )
          : null;

        // custom function to build the documents for rendering
        const buildPoliciesForRendering = documents => {
          if (documents && documents.edges) {
            return {
              ...documents,
              edges: documents.edges.map(({ node }) => ({
                ...{
                  node: {
                    ...(adoptedMapping[node.pk] || node),
                    originPk: node.pk,
                    onChangeAdoptedDocument,
                    documentsOfTemplate: constructDocumentsOfTemplate(node)
                  }
                }
              }))
            };
          }
          return documents;
        };

        return (
          <ErrorCheckWrapper queryName="documentsToReview" {...props}>
            {isTemplateView && hasTemplateInDocuments && (
              <InfoBanner
                message={t(
                  "documentReports.expiring.titleView.templateInfoBanner",
                  {
                    documentLabelPlural: labels.documentLabelPlural.toLowerCase()
                  }
                )}
                width="100%"
              />
            )}
            <AdvancedReportPage
              csvQueryName="documentsToReviewCsv"
              csvQueryArgs={{
                justMine,
                includePending,
                categories: categoriesArg,
                daysUntilExpiration,
                reviewType
              }}
              CSVQuery={DocumentsForReviewCSVQuery}
              variables={variables}
              data={buildPoliciesForRendering(documents)}
              route={route}
              router={router}
              location={location}
              tabs={tabs}
              headerText={headerText}
              noContentMessage={t(
                "documentReports.expiring.reportContent.noContentMessage",
                {
                  documentLabelPlural: labels.documentLabelPlural.toLowerCase()
                }
              )}
              showActionButtonPanel={true}
              showTabsPanel={true}
              tableType={tableType}
              renderActionPanel={selectedPolicies => (
                <DefaultActionPanel
                  selectedItemPks={selectedPolicies}
                  actionTextKey={
                    "documentReports.expiring.pendForApprovalButton"
                  }
                  onActionButtonClick={handleBulkReview}
                  loading={loading}
                />
              )}
              BucketsComponent={() => (
                <Buckets
                  bucketsConfig={getBucketsConfig(t, isTemplateView, buckets)}
                />
              )}
              otherTableProps={{ isCheckboxDisabled }}
              isCheckboxDisabled={isCheckboxDisabled}
              rssFeedUrl={rssFeedUrl}
              renderFilterPanel={() => (
                <SearchBarAndFilters
                  location={location}
                  router={router}
                  categories={categories}
                  waitingSinceFilter={isTemplateView}
                />
              )}
              PrefixHeaderComponent={PrefixHeaderComponent}
            />
          </ErrorCheckWrapper>
        );
      }}
    />
  );
}

export default withLabelContext(DocumentsToReviewReport);
