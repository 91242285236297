import { addDays } from "date-fns";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { createPolicyStartApprovals } from "../../create_policy/CreatePolicyStartApprovalsMutation";
import { editPolicyStartApprovals } from "../../edit_policy/EditPolicyStartApprovalsMutation";
import { getOnesourceDocumentInputData } from "../../onesource/";
import { useOnesourceDocuments } from "../../onesource/OneSourceContext";
import { overrideDocumentMutation } from "../../override_policy/OverrideMutation";
import { checkDefaultExpiration } from "../components/DefaultExpirationWarning";
import {
  EFFECTIVE_DATE_DATETIME,
  EFFECTIVE_DATE_TIMEDELTA,
  EFFECTIVE_DATE_UPON_APPROVAL,
  getEffectiveDateAlgorithm as getDateAlgorithm
} from "../components/EffectiveDate";
import {
  ACTIVE_LIFESPAN,
  EXPIRATION_DATE
} from "../components/PolicyProperties";
import getCategoryLifespan from "../getCategoryLifespan";
import { saveDocument } from "../SaveDocumentMutation";
import { isCreatePage, isOverridePage } from "../utils";

import { createErrorMessage } from "pstat-design-system/message/manageMessages";
import { isPending } from "pstat-anywhere/utils/document";
import {
  dateParser,
  getDifferenceInDays,
  validateAndFormatDate
} from "pstat-anywhere/utils/dateHelpers";
import { environment } from "pstat-anywhere/relay";

const useRequiresComment = (alreadyChanged, isCreatePage) => {
  const [requiresComment, setRequiresComment] = useState(alreadyChanged);

  const handleChange = useCallback(
    (currentValue, initialValue) => {
      const commentIsRequired = (currentValue, initialValue) => {
        if (isCreatePage) {
          return false;
        }
        if (alreadyChanged) {
          return true;
        }
        if (initialValue !== currentValue) {
          return true;
        }
        return false;
      };
      setRequiresComment(commentIsRequired(currentValue, initialValue));
    },
    [alreadyChanged, isCreatePage]
  );
  return [requiresComment, handleChange];
};

export default function usePolicyEditor({
  type,
  document,
  documentSave,
  editForm,
  tagForm,
  scheduledEffectiveDateForm,
  systemDocumentTemplateForm,
  preimportRevisionForm,
  setSubmitModalIsOpen,
  setSubmitModalLoading,
  alreadyChanged,
  router,
  createFromWord,
  enabledAutoSaveTimeout,
  isClone,
  blankFlow,
  blankLifespan,
  saveLocations
}) {
  const [noChangesMade, setNoChangesMade] = useState(true);
  const [autoSave, setAutoSave] = useState(true);
  const [changesSaved, setChangesSaved] = useState(false);
  const [saving, setSaving] = useState(false);
  const autoSaveCountdownTimeout = useRef(null);
  const autoSaveRequestTimeout = useRef(null);
  const [initialWarning, setInitialWarning] = useState(true);
  const [expirationWarning, setExpirationWarning] = useState(null);
  const [newSavedDraftUrl, setNewSavedDraftUrl] = useState(null);
  const [commentDocumentSave, setCommentDocumentSave] = useState(null);

  const { t } = useTranslation();

  // `autosaveCountdown` must always be bigger than `saveRequest`,
  // otherwise a broken save will cause a deadlock when submitting
  const timerOptions = {
    saveRequestTimeout: 5000,
    autosaveCountdown: 7000
  };
  const overridePage = isOverridePage(type);
  const overridingPendingDocument = overridePage && isPending(document.status);

  const defaultFormValues = {
    name: "",
    author: null,
    category: null,
    flowTemplate: null,
    bloc: null,
    activeLifespan: null,
    expirationDate: null,
    references: [],
    html: "",
    effectiveDateTimedelta: 0,
    effectiveDateDatetime: null,
    attachments: [],
    approver: null,
    approvedDate: null,
    hidePDFWatermark: false,
    restricted: false,
    regulations: [],
    revisionDates: [],
    locations: []
  };
  let initialName = defaultFormValues["name"];
  // author defaults selected, even on create page, unless logged in as superuser
  let initialAuthor = editForm.author
    ? editForm.author.pk
    : defaultFormValues["author"];
  let initialCategory = defaultFormValues["category"];
  let initialFlowTemplate = defaultFormValues["flowTemplate"];
  let initialBloc = defaultFormValues["bloc"];
  let initialActiveLifespan = defaultFormValues["activeLifespan"];
  let initialExpirationDate = defaultFormValues["expirationDate"];
  let initialReferences = defaultFormValues["references"];
  let initialHtml = editForm.html;
  let initialEffectiveDateTimedelta =
    defaultFormValues["effectiveDateTimedelta"];
  let initialEffectiveDateDatetime = defaultFormValues["effectiveDateDatetime"];
  let initialApprover = defaultFormValues["approver"];
  let initialApprovedDate = defaultFormValues["approvedDate"];
  let initialAttachments = defaultFormValues["attachments"];
  let initialHidePDFWatermark = defaultFormValues["hidePDFWatermark"];
  let initialRestricted = defaultFormValues["restricted"];
  let initialRegulations = defaultFormValues["regulations"];
  let initialRevisionDates = defaultFormValues["revisionDates"];
  let initialLocations = defaultFormValues["locations"];

  // override inital values
  let initialApprovalDate = null;
  let initialOriginationDate = null;
  let initialLegacyEffectiveDate = null;

  if (createFromWord) {
    initialName = createFromWord.name;
    initialHtml = createFromWord.html;
  }

  if (document) {
    initialName = editForm.name;
    initialAuthor = editForm.author.pk;
    if (!isClone) {
      initialCategory = editForm.category.pk;
      if (!blankFlow) {
        initialFlowTemplate =
          editForm?.flowTemplate?.pk || defaultFormValues["flowTemplate"];
      }
    }
    initialBloc = editForm.bloc.pk;
    if (!blankLifespan) {
      initialActiveLifespan = editForm.activeLifespan;
    }
    initialExpirationDate = editForm.expirationDate;
    initialReferences = tagForm.tags.map(tag => tag.pk);
    initialHtml = editForm.html;
    initialAttachments = [];
    initialHidePDFWatermark = editForm.isAForm;
    initialRestricted = editForm.restricted;
    initialRegulations = editForm.regulations;
    if (scheduledEffectiveDateForm) {
      initialEffectiveDateTimedelta =
        scheduledEffectiveDateForm.effectiveDateTimedelta;
      initialEffectiveDateDatetime = scheduledEffectiveDateForm.effectiveDate;
    }
    if (systemDocumentTemplateForm) {
      initialLocations = systemDocumentTemplateForm.blocs.map(bloc => bloc.pk);
    }

    if (overridePage) {
      initialApprovalDate = editForm.approvalDate;
      initialLegacyEffectiveDate = editForm.legacyEffectiveDate;
      if (scheduledEffectiveDateForm) {
        initialOriginationDate = scheduledEffectiveDateForm.originationDate;
      }
      if (preimportRevisionForm) {
        initialRevisionDates = preimportRevisionForm.revisionDates;
      }
    }
  }
  const [formError, setFormError] = useState({
    name: null,
    author: null,
    category: null,
    flow_template: null,
    bloc: null,
    active_lifespan: null,
    expiration_date: null,
    references: null,
    effective_date: null,
    origination_date: null,
    legacy_effective_date: null,
    approval_date: null,
    action_comment: null,
    regulations: null,
    approver: null,
    approved_date: null,
    locations: null
  });

  // policy state
  const [name, setName] = useState(initialName);
  const [author, setAuthor] = useState(initialAuthor);
  const [category, setCategory] = useState(initialCategory);
  const [flowTemplate, setFlowTemplate] = useState(initialFlowTemplate);
  const [bloc, setBloc] = useState(initialBloc);
  const [activeLifespan, setActiveLifespan] = useState(initialActiveLifespan);
  const [expirationDate, setExpirationDate] = useState(initialExpirationDate);
  const [references, setReferences] = useState(initialReferences);
  const [html, setHtml] = useState(initialHtml);
  const [effectiveDateTimedelta, setEffectiveDateTimedelta] = useState(
    initialEffectiveDateTimedelta
  );
  const [effectiveDateDatetime, setEffectiveDateDatetime] = useState(
    initialEffectiveDateDatetime
  );
  const [attachments, setAttachments] = useState(initialAttachments);
  const [hidePDFWatermark, setHidePDFWatermark] = useState(
    initialHidePDFWatermark
  );
  const [restricted, setRestricted] = useState(initialRestricted);
  const [regulations, setRegulations] = useState(initialRegulations);
  const [revisionDates, setRevisionDates] = useState(initialRevisionDates);
  const [locations, setLocations] = useState(initialLocations);

  // override fields
  const [approvalDate, setApprovalDate] = useState(initialApprovalDate);
  const [originationDate, setOriginationDate] = useState(
    initialOriginationDate
  );
  const [legacyEffectiveDate, setLegacyEffectiveDate] = useState(
    initialLegacyEffectiveDate
  );

  // approval simulation fields
  const [approver, setApprover] = useState(initialApprover);
  const [approvedDate, setApprovedDate] = useState(initialApprovedDate);
  // state comes from onesource context
  const { onesourceDocuments } = useOnesourceDocuments();

  const policyForm = {
    name,
    author,
    category,
    flowTemplate,
    bloc,
    activeLifespan,
    expirationDate,
    references,
    html,
    effectiveDateTimedelta,
    effectiveDateDatetime,
    attachments,
    hidePDFWatermark,
    restricted,
    approvalDate,
    originationDate,
    legacyEffectiveDate,
    regulations,
    approver,
    approvedDate,
    revisionDates,
    locations
  };

  const [errorFound, setErrorFound] = useState(false);
  const [activeLifespanSelected, setActiveLifespanSelected] = useState(
    !initialExpirationDate
  );
  let initialEffectiveDateOption = EFFECTIVE_DATE_UPON_APPROVAL;
  if (
    scheduledEffectiveDateForm &&
    scheduledEffectiveDateForm.effectiveDateAlgorithm === "timedelta"
  ) {
    initialEffectiveDateOption = EFFECTIVE_DATE_TIMEDELTA;
  } else if (
    scheduledEffectiveDateForm &&
    scheduledEffectiveDateForm.effectiveDateAlgorithm === "datetime"
  ) {
    initialEffectiveDateOption = EFFECTIVE_DATE_DATETIME;
  }
  const [
    effectiveDateSelectedOption,
    setEffectiveDateSelectedOption
  ] = useState(initialEffectiveDateOption);
  const [showPreview, setShowPreview] = useState(false);
  const [commentRequired, updateCommentRequired] = useRequiresComment(
    alreadyChanged,
    isCreatePage(type)
  );

  const clearPreviousAutoSaveCountdown = () => {
    clearTimeout(autoSaveCountdownTimeout.current);
    autoSaveCountdownTimeout.current = null;
  };

  const toggleEffectiveDateOption = useCallback(
    optionInFocus => {
      setEffectiveDateSelectedOption(optionInFocus);
      setNoChangesMade(false);
      setChangesSaved(false);
    },
    [setEffectiveDateSelectedOption, setNoChangesMade, setChangesSaved]
  );

  const clearErrorForField = useCallback(
    field => {
      const errors = { ...formError };
      errors[field] = null;
      setFormError(errors);

      const foundError = Object.keys(errors).some(field => errors[field]);
      setErrorFound(foundError);
    },
    [formError]
  );

  const toggleReviewDateOption = useCallback(
    optionInFocus => {
      setActiveLifespanSelected(optionInFocus === ACTIVE_LIFESPAN);
      setNoChangesMade(false);
      setChangesSaved(false);
      clearErrorForField("active_lifespan");
      clearErrorForField("expiration_date");
    },
    [
      clearErrorForField,
      setActiveLifespanSelected,
      setNoChangesMade,
      setChangesSaved
    ]
  );

  // set up input change handlers
  const useChangeHandler = (setStateFunc, errorField) =>
    useCallback(
      value => {
        setStateFunc && setStateFunc(value);
        setNoChangesMade(false);
        setChangesSaved(false);
        if (errorField) {
          clearErrorForField(errorField);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [setStateFunc, errorField, formError]
      // the formError is needed for the clearErrorForField to get the updated formError state
    );

  const handleAttachmentChange = useChangeHandler();

  const handleNameChange = useChangeHandler(
    useCallback(
      value => {
        setName(value);
        updateCommentRequired(value, initialName);
      },
      [initialName, setName, updateCommentRequired]
    ),
    "name"
  );
  const handleAuthorChange = useChangeHandler(setAuthor, "author");
  const handleCategoryChange = useChangeHandler(
    useCallback(
      value => {
        setCategory(value);
        updateCommentRequired(value, initialCategory);
        if (!overridePage || overridingPendingDocument) {
          if (!activeLifespan && value) {
            getCategoryLifespan(value, setActiveLifespan);
          }
          if (activeLifespanSelected) {
            const documentPk = document ? document.pk : null;
            checkDefaultExpiration(
              activeLifespan,
              documentPk,
              value,
              initialWarning,
              setExpirationWarning
            );
            setInitialWarning(false);
          }
        }
      },
      [
        updateCommentRequired,
        initialCategory,
        activeLifespan,
        activeLifespanSelected,
        overridePage,
        overridingPendingDocument,
        document,
        initialWarning
      ]
    ),
    "category"
  );
  const handleFlowTemplateChange = useChangeHandler(
    setFlowTemplate,
    "flow_template"
  );
  const handleBlocChange = useChangeHandler(
    useCallback(
      value => {
        setBloc(value);
        updateCommentRequired(value, initialBloc);
      },
      [initialBloc, setBloc, updateCommentRequired]
    ),
    "bloc"
  );
  const handleActiveLifespanChange = useChangeHandler(
    useCallback(
      value => {
        toggleReviewDateOption(ACTIVE_LIFESPAN);
        setActiveLifespan(value);
        if (overridePage) {
          updateCommentRequired(value, initialActiveLifespan);
        }
        if (!overridePage || overridingPendingDocument) {
          if (activeLifespanSelected) {
            const documentPk = document ? document.pk : null;
            checkDefaultExpiration(
              value,
              documentPk,
              category,
              initialWarning,
              setExpirationWarning
            );
            setInitialWarning(false);
          }
        }
      },
      [
        toggleReviewDateOption,
        overridePage,
        overridingPendingDocument,
        updateCommentRequired,
        initialActiveLifespan,
        activeLifespanSelected,
        document,
        category,
        initialWarning
      ]
    ),
    "active_lifespan"
  );

  const handleActiveLifespanDateCheck = useCallback(
    ({ newLifeSpanDays, newApprovalDate, setModalData }) => {
      if (isPending(document.status) || !overridePage) {
        return;
      }

      const parsedNewLifespan = Number(newLifeSpanDays || activeLifespan);
      const parsedApprovalDate = newApprovalDate || approvalDate;

      const actualLifespanDays = getDifferenceInDays(
        expirationDate,
        parsedApprovalDate
      );

      if (actualLifespanDays !== parsedNewLifespan) {
        setModalData({
          isOpen: true,
          actualLifespanDays,
          newLifeSpanDays: parsedNewLifespan
        });
      }
    },
    [activeLifespan, approvalDate, document, expirationDate, overridePage]
  );

  const handleExpirationDateChange = useChangeHandler(
    useCallback(
      value => {
        toggleReviewDateOption(EXPIRATION_DATE);
        setExpirationDate(value);
        if (overridePage) {
          updateCommentRequired(value, initialExpirationDate);
        }
      },
      [
        initialExpirationDate,
        toggleReviewDateOption,
        setExpirationDate,
        updateCommentRequired,
        overridePage
      ]
    ),
    "expiration_date"
  );

  const handleInconsistentExpirationDateUpdate = useCallback(
    newLifespan => {
      const dateObject = dateParser(approvalDate);
      const newDate = addDays(dateObject, newLifespan);

      const formatedNewDate = validateAndFormatDate({
        date: newDate,
        formatString: "year-month-day"
      });

      handleExpirationDateChange(formatedNewDate);
    },
    [approvalDate, handleExpirationDateChange]
  );

  const handleReferencesChange = useChangeHandler(setReferences, "references");
  const handleHtmlChange = useChangeHandler(
    useCallback(
      value => {
        setHtml(value);
        updateCommentRequired(value, initialHtml);
      },
      [initialHtml, setHtml, updateCommentRequired]
    )
  );

  const handleDataReady = useCallback(
    value => {
      setHtml(value);
    },
    [setHtml]
  );

  const handleEffectiveDateTimedeltaChange = useChangeHandler(value => {
    toggleEffectiveDateOption(EFFECTIVE_DATE_TIMEDELTA);
    setEffectiveDateTimedelta(value);
  });
  const handleEffectiveDateDatetimeChange = useChangeHandler(
    useCallback(
      value => {
        toggleEffectiveDateOption(EFFECTIVE_DATE_DATETIME);
        setEffectiveDateDatetime(value);
        if (overridePage) {
          updateCommentRequired(value, initialEffectiveDateDatetime);
        }
      },
      [
        initialEffectiveDateDatetime,
        toggleEffectiveDateOption,
        setEffectiveDateDatetime,
        updateCommentRequired,
        overridePage
      ]
    ),
    "effective_date"
  );
  const handleHidePDFWatermarkChange = useChangeHandler(setHidePDFWatermark);
  const handleRestrictedChange = useChangeHandler(
    useCallback(
      value => {
        setRestricted(value);
        updateCommentRequired(value, initialRestricted);
      },
      [initialRestricted, setRestricted, updateCommentRequired]
    )
  );
  const handleRegulationsChange = useChangeHandler(
    setRegulations,
    "regulations"
  );
  const handleLocationsChange = useChangeHandler(setLocations, "locations");
  const handleApprovalDateChange = useChangeHandler(
    useCallback(
      value => {
        setApprovalDate(value);
        updateCommentRequired(value, initialApprovalDate);
      },
      [initialApprovalDate, setApprovalDate, updateCommentRequired]
    ),
    "approval_date"
  );
  const handleOriginationDateChange = useChangeHandler(
    setOriginationDate,
    "origination_date"
  );
  const handleLegacyEffectiveDateChange = useChangeHandler(
    useCallback(
      value => {
        setLegacyEffectiveDate(value);
        updateCommentRequired(value, initialLegacyEffectiveDate);
      },
      [
        initialLegacyEffectiveDate,
        setLegacyEffectiveDate,
        updateCommentRequired
      ]
    ),
    "legacy_effective_date"
  );
  const handleApproverChange = useChangeHandler(setApprover, "approver");
  const handleApprovedDateChange = useChangeHandler(
    setApprovedDate,
    "approved_date"
  );
  const handleRevisionDatesChange = useChangeHandler(
    setRevisionDates,
    "revisionDates"
  );

  // set up clear input handlers
  const useClearHandler = (setStateFunc, defaultValue) =>
    useCallback(() => {
      setStateFunc(defaultValue);
      clearPreviousAutoSaveCountdown();
      setNoChangesMade(false);
      setChangesSaved(false);
    }, [defaultValue, setStateFunc]);
  const handleNameClear = useClearHandler(setName, defaultFormValues["name"]);
  const handleAuthorClear = useClearHandler(
    setAuthor,
    defaultFormValues["author"]
  );
  const handleCategoryClear = useClearHandler(
    setCategory,
    defaultFormValues["category"]
  );
  const handleFlowTemplateClear = useClearHandler(
    setFlowTemplate,
    defaultFormValues["flowTemplate"]
  );
  const handleBlocClear = useClearHandler(setBloc, defaultFormValues["bloc"]);
  const handleReferencesClear = useClearHandler(
    setReferences,
    defaultFormValues["references"]
  );
  const handleRegulationsClear = useClearHandler(
    setRegulations,
    defaultFormValues["regulations"]
  );
  const handleLocationsClear = useClearHandler(
    setLocations,
    defaultFormValues["locations"]
  );
  const handleApproverClear = useClearHandler(
    setApprover,
    defaultFormValues["approver"]
  );
  const handleApprovedDateClear = useClearHandler(
    setApprover,
    defaultFormValues["approvedDate"]
  );
  const handleRevisionDatesClear = useClearHandler(
    setRevisionDates,
    defaultFormValues["revisionDates"]
  );

  const updateAttachments = useCallback(
    attachments => {
      setAttachments(attachments);
    },
    [setAttachments]
  );

  const showFormErrors = useCallback(
    reasons => {
      const formErrorCopy = { ...formError };
      Object.keys(formErrorCopy).forEach(field => {
        if (reasons[field]) {
          formErrorCopy[field] = reasons[field];
        }
      });
      setFormError(formErrorCopy);
      setErrorFound(true);
      setSubmitModalIsOpen(false);
      setSubmitModalLoading(false);
    },
    [formError, setSubmitModalIsOpen, setSubmitModalLoading]
  );

  const handleSubmitModalMutationResponse = (
    mutationResponse,
    errors,
    errorMessage,
    closeModal
  ) => {
    if (errors) {
      createErrorMessage(errorMessage);
      setSubmitModalIsOpen(false);
      setSubmitModalLoading(false);
    } else if (mutationResponse.redirectUrl) {
      router.push(mutationResponse.redirectUrl);
    } else if (mutationResponse && mutationResponse.ok) {
      setSubmitModalIsOpen(false);
      setSubmitModalLoading(false);
    } else if (mutationResponse && mutationResponse.reasons) {
      showFormErrors(mutationResponse.reasons);
      closeModal();
    } else {
      createErrorMessage(errorMessage);
      closeModal();
      setSubmitModalIsOpen(false);
      setSubmitModalLoading(false);
    }
  };

  const stringifyReferences = references => {
    return references.map(reference => {
      if (reference && typeof reference === "object") {
        return JSON.stringify(reference);
      }
      return `${reference}`;
    });
  };

  const startApprovalFlow = (comment, markApproval, closeModal) => {
    if (validateFields()) {
      const startApprovalVariables = {
        documentPk: document ? document.pk : null,
        documentSaveId: documentSave.pk,
        name: name,
        author: author,
        category: category,
        flowTemplate: flowTemplate,
        bloc: bloc,
        html: html,
        tagInput: stringifyReferences(references),
        actionComment: comment,
        effectiveDate: effectiveDateDatetime,
        effectiveDateTimedelta: parseInt(effectiveDateTimedelta, 10),
        effectiveDateAlgorithm: getEffectiveDateAlgorithm(),
        isAForm: hidePDFWatermark,
        restricted: restricted,
        addApproval: markApproval,
        regulations: regulations,
        onesourceDocuments: getOnesourceDocumentInputData(onesourceDocuments)
      };
      if (saveLocations) {
        startApprovalVariables.locations = locations;
      }
      const [selectedReviewDateOption, value] = getReviewDateOption();
      startApprovalVariables[selectedReviewDateOption] = value;

      const startApprovalsMutation = isCreatePage(type)
        ? createPolicyStartApprovals
        : editPolicyStartApprovals;
      const startApprovalsField = isCreatePage(type)
        ? "createPolicyStartApprovals"
        : "editPolicyStartApprovals";

      setSubmitModalLoading(true);
      startApprovalsMutation(
        environment,
        startApprovalVariables,
        (response, errors) => {
          const startApprovals = response[startApprovalsField];
          handleSubmitModalMutationResponse(
            startApprovals,
            errors,
            t("documentControl.create.startApprovals.error"),
            closeModal
          );
        }
      );
    } else {
      closeModal();
      setChangesSaved(false);
      setNoChangesMade(false);
    }
  };

  const overrideDocument = (comment, closeModal) => {
    if (!validateFields()) {
      closeModal();
      setNoChangesMade(false);
    }
    const overrideVariables = {
      documentPk: document.pk,
      name: name,
      author: author,
      category: category,
      flowTemplate: flowTemplate,
      bloc: bloc,
      html: html,
      tagInput: stringifyReferences(references),
      originationDate: originationDate,
      legacyEffectiveDate: legacyEffectiveDate,
      approvalDate: approvalDate,
      actionComment: comment,
      effectiveDate: effectiveDateDatetime,
      effectiveDateTimedelta: parseInt(effectiveDateTimedelta, 10),
      effectiveDateAlgorithm: getEffectiveDateAlgorithm(),
      isAForm: hidePDFWatermark,
      restricted: restricted,
      regulations: regulations,
      approver: approver,
      approvedDate: approvedDate,
      revisionDates: revisionDates,
      onesourceDocuments: getOnesourceDocumentInputData(onesourceDocuments)
    };
    if (saveLocations) {
      overrideVariables.locations = locations;
    }
    if (isPending(document.status)) {
      const [selectedReviewDateOption, value] = getReviewDateOption();
      overrideVariables[selectedReviewDateOption] = value;
    } else {
      overrideVariables.expirationDate = expirationDate;
      overrideVariables.activeLifespan = activeLifespan;
    }

    setSubmitModalLoading(true);
    overrideDocumentMutation(
      environment,
      overrideVariables,
      (response, errors) => {
        const override = response["override"];
        handleSubmitModalMutationResponse(
          override,
          errors,
          t("documentControl.override.error"),
          closeModal
        );
      }
    );
  };

  const getEffectiveDateAlgorithm = useCallback(
    () => getDateAlgorithm(effectiveDateSelectedOption),
    [effectiveDateSelectedOption]
  );

  const getReviewDateOption = useCallback(() => {
    if (activeLifespanSelected) {
      return [ACTIVE_LIFESPAN, activeLifespan];
    }
    return [EXPIRATION_DATE, expirationDate];
  }, [activeLifespan, activeLifespanSelected, expirationDate]);

  const toggleAutoSave = () => {
    setAutoSave(!autoSave);
  };

  const validateFields = useCallback(() => {
    let isValid = true;
    const required = { name, author, category, flowTemplate };
    if (editForm.blocChoices.length > 1) {
      required.bloc = bloc;
    }

    const error = formError;
    Object.keys(error).forEach(field => {
      error[field] = null;
    });
    Object.keys(required).forEach(field => {
      // Checking for blank fields that are required
      if (!required[field]) {
        error[field] = [t("form.missingRequired")];
        isValid = false;
      }
    });
    let [selectedOption, value] = getReviewDateOption();
    if (!selectedOption || !value) {
      const missingFieldError = [t("form.missingRequired")];
      if (selectedOption === ACTIVE_LIFESPAN) {
        error.active_lifespan = missingFieldError;
      } else {
        error.expiration_date = missingFieldError;
      }
      isValid = false;
    }
    setFormError(error);
    setErrorFound(!isValid);
    return isValid;
  }, [
    name,
    author,
    category,
    flowTemplate,
    editForm.blocChoices.length,
    formError,
    getReviewDateOption,
    bloc,
    t
  ]);

  const savePolicyDependencies = [
    name,
    author,
    category,
    flowTemplate,
    bloc,
    references,
    html,
    effectiveDateTimedelta,
    effectiveDateDatetime,
    hidePDFWatermark,
    restricted,
    regulations,
    onesourceDocuments,
    getEffectiveDateAlgorithm,
    getReviewDateOption,
    showFormErrors,
    validateFields
  ];
  if (!overridePage) {
    savePolicyDependencies.push(documentSave.pk);
  }
  const savePolicy = useCallback(() => {
    clearPreviousAutoSaveCountdown();
    setSaving(true);
    if (validateFields()) {
      const documentSaveVariables = {
        pk: documentSave.pk,
        name: name,
        author: author,
        category: category,
        flowTemplate: flowTemplate,
        bloc: bloc,
        html: html,
        tagInput: stringifyReferences(references),
        effectiveDate: effectiveDateDatetime,
        effectiveDateTimedelta: parseInt(effectiveDateTimedelta, 10),
        effectiveDateAlgorithm: getEffectiveDateAlgorithm(),
        isAForm: hidePDFWatermark,
        restricted: restricted,
        regulations: regulations,
        onesourceDocuments: getOnesourceDocumentInputData(onesourceDocuments)
      };
      if (saveLocations) {
        documentSaveVariables.locations = locations;
      }
      const [selectedOption, value] = getReviewDateOption();
      documentSaveVariables[selectedOption] = value;

      const clearAutoSaveRequestTimeout = () => {
        clearTimeout(autoSaveRequestTimeout.current);
        autoSaveRequestTimeout.current = null;
      };

      saveDocument(environment, documentSaveVariables, (response, errors) => {
        setSaving(false);
        clearAutoSaveRequestTimeout();
        if (errors) {
          createErrorMessage(t("documentControl.save.error"));
          setChangesSaved(false);
          setNoChangesMade(false);
        } else if (response.saveDraft && response.saveDraft.ok) {
          setChangesSaved(true);
          setNoChangesMade(true);
          setNewSavedDraftUrl(response.saveDraft.draftViewUrl);
          setCommentDocumentSave(response.saveDraft.documentSave);
        } else if (response.saveDraft && response.saveDraft.reasons) {
          const reasons = response.saveDraft.reasons;
          showFormErrors(reasons);
        } else {
          createErrorMessage(t("documentControl.save.error"));
          setChangesSaved(false);
          setNoChangesMade(false);
        }
      });

      if (enabledAutoSaveTimeout) {
        autoSaveRequestTimeout.current = setTimeout(() => {
          setSaving(false);
          setChangesSaved(false);
          setNoChangesMade(false);
          startAutoSaveCountdown();
        }, timerOptions.saveRequestTimeout);
      }
    } else {
      setSaving(false);
      setChangesSaved(false);
      setNoChangesMade(false);
    }
    // The eslint plugin to check for these dependencies
    // doesn't like if the dependencies aren't defined as an array literal here.
    // It will auto add the dependencies if this isn't disabled and break things
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, savePolicyDependencies);

  const previewPolicy = () => {
    setShowPreview(true);
  };

  const startAutoSaveCountdown = () => {
    autoSaveCountdownTimeout.current = setTimeout(
      savePolicy,
      timerOptions.autosaveCountdown
    );
  };

  useEffect(() => {
    if (!overridePage) {
      if (!noChangesMade) {
        if (autoSave) {
          clearPreviousAutoSaveCountdown();
          startAutoSaveCountdown();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    noChangesMade,
    name,
    author,
    category,
    flowTemplate,
    bloc,
    activeLifespan,
    expirationDate,
    references,
    html,
    effectiveDateTimedelta,
    effectiveDateDatetime,
    attachments,
    hidePDFWatermark,
    restricted,
    regulations,
    autoSave,
    overridePage,
    savePolicy
  ]);

  if (initialWarning && activeLifespanSelected) {
    if (!overridePage || overridingPendingDocument) {
      const documentPk = document ? document.pk : null;
      checkDefaultExpiration(
        activeLifespan,
        documentPk,
        category,
        initialWarning,
        setExpirationWarning
      );
      setInitialWarning(false);
    }
  }

  const setChangesMade = useCallback(() => {
    setNoChangesMade(false);
    setChangesSaved(false);
  }, []);

  return {
    // State fields
    noChangesMade,
    autoSave,
    changesSaved,
    saving,
    formError,
    policyForm,
    errorFound,
    activeLifespanSelected,
    effectiveDateSelectedOption,
    commentRequired,
    showPreview,
    effectiveDate: effectiveDateDatetime,
    effectiveDateTimedelta: parseInt(effectiveDateTimedelta, 10),
    effectiveDateAlgorithm: getEffectiveDateAlgorithm(),
    expirationWarning,
    newSavedDraftUrl,
    // Handlers
    handleAttachmentChange,
    handleNameChange,
    handleNameClear,
    handleAuthorChange,
    handleAuthorClear,
    handleCategoryChange,
    handleCategoryClear,
    handleFlowTemplateChange,
    handleFlowTemplateClear,
    handleBlocChange,
    handleBlocClear,
    handleActiveLifespanChange,
    handleActiveLifespanDateCheck,
    handleExpirationDateChange,
    handleInconsistentExpirationDateUpdate,
    handleReferencesChange,
    handleReferencesClear,
    handleHtmlChange,
    handleDataReady,
    handleEffectiveDateTimedeltaChange,
    handleEffectiveDateDatetimeChange,
    handleHidePDFWatermarkChange,
    handleRestrictedChange,
    handleRegulationsChange,
    handleRegulationsClear,
    handleLocationsChange,
    handleLocationsClear,
    handleApprovalDateChange,
    handleOriginationDateChange,
    handleLegacyEffectiveDateChange,
    handleApproverChange,
    handleApproverClear,
    handleApprovedDateChange,
    handleApprovedDateClear,
    handleRevisionDatesChange,
    handleRevisionDatesClear,
    setAttachments,
    updateAttachments,
    toggleReviewDateOption,
    toggleEffectiveDateOption,
    startApprovalFlow,
    overrideDocument,
    savePolicy,
    previewPolicy,
    toggleAutoSave,
    setShowPreview,
    setChangesMade,
    commentDocumentSave
  };
}

export { default as useBeforeUnload } from "./useBeforeUnload";
