/**
 * @flow
 * @relayHash 0dc6e7c17c65c6b6577ce1600e96d7b8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ApplicabilityType = "LOCAL" | "LOCALIZED" | "SYSTEM" | "%future added value";
export type CannotBulkReviewReason = "HAS_DRAFT" | "INACTIVE_AUTHOR" | "SPECIFIC_EXPIRATION_DATE" | "%future added value";
export type ReviewType = "TEMPLATE" | "TIME" | "%future added value";
export type RevisionStatus = "NEW" | "REVISED" | "UNCHANGED" | "%future added value";
export type DocumentsForReviewQueryVariables = {|
  after?: ?string,
  first?: ?number,
  sort?: ?string,
  justMine?: ?boolean,
  searchQuery?: ?string,
  categories?: ?$ReadOnlyArray<?number>,
  daysUntilExpiration?: ?number,
  daysWaitingSince?: ?number,
  includePending?: ?boolean,
  reviewType?: ?ReviewType,
|};
export type DocumentsForReviewQueryResponse = {|
  +documentsToReview: ?{|
    +documents: ?{|
      +totalCount: ?number,
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +pk: ?number,
          +name: string,
          +category: {|
            +name: string
          |},
          +expirationDate: ?string,
          +publishDate: ?string,
          +restricted: boolean,
          +preview: ?string,
          +bloc: {|
            +name: string
          |},
          +applicabilityType: ?ApplicabilityType,
          +author: {|
            +firstName: string,
            +lastName: string,
            +title: ?string,
          |},
          +cannotBulkReviewReason: ?CannotBulkReviewReason,
          +hasPendingChild: ?boolean,
          +hasAttachments: ?boolean,
          +isTemplate: ?boolean,
          +revisedFromTemplateStatus: ?RevisionStatus,
          +documentsOfTemplate: ?{|
            +documents: ?$ReadOnlyArray<?{|
              +pk: ?number,
              +name: string,
              +preview: ?string,
              +hasAttachments: ?boolean,
              +bloc: {|
                +name: string,
                +pk: ?number,
              |},
              +applicabilityType: ?ApplicabilityType,
              +category: {|
                +name: string
              |},
              +expirationDate: ?string,
            |}>
          |},
        |}
      |}>,
    |},
    +buckets: ?any,
    +showBulkReview: ?boolean,
    +rssFeedUrl: ?string,
    +counts: ?{|
      +timeCount: ?number,
      +templateCount: ?number,
    |},
    +categories: ?$ReadOnlyArray<?{|
      +pk: ?number,
      +name: string,
    |}>,
    +error: ?{|
      +statusCode: ?number,
      +message: ?string,
    |},
  |}
|};
export type DocumentsForReviewQuery = {|
  variables: DocumentsForReviewQueryVariables,
  response: DocumentsForReviewQueryResponse,
|};
*/


/*
query DocumentsForReviewQuery(
  $after: String
  $first: Int
  $sort: String
  $justMine: Boolean
  $searchQuery: String
  $categories: [Int]
  $daysUntilExpiration: Int
  $daysWaitingSince: Int
  $includePending: Boolean
  $reviewType: ReviewType
) {
  documentsToReview(q: $searchQuery, justMine: $justMine, sort: $sort, categories: $categories, daysUntilExpiration: $daysUntilExpiration, daysWaitingSince: $daysWaitingSince, includePending: $includePending, reviewType: $reviewType) {
    documents(after: $after, first: $first) {
      totalCount
      edges {
        node {
          pk
          name
          category {
            name
            id
          }
          expirationDate
          publishDate
          restricted
          preview
          bloc {
            name
            id
          }
          applicabilityType
          author {
            firstName
            lastName
            title
            id
          }
          cannotBulkReviewReason
          hasPendingChild
          hasAttachments
          isTemplate
          revisedFromTemplateStatus
          documentsOfTemplate {
            documents {
              pk
              name
              preview
              hasAttachments
              bloc {
                name
                pk
                id
              }
              applicabilityType
              category {
                name
                id
              }
              expirationDate
              id
            }
          }
          id
        }
      }
    }
    buckets
    showBulkReview
    rssFeedUrl
    counts {
      timeCount
      templateCount
    }
    categories {
      pk
      name
      id
    }
    error {
      statusCode
      message
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "sort",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "justMine",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "searchQuery",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "categories",
    "type": "[Int]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "daysUntilExpiration",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "daysWaitingSince",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "includePending",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "reviewType",
    "type": "ReviewType",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "categories",
    "variableName": "categories",
    "type": "[Int]"
  },
  {
    "kind": "Variable",
    "name": "daysUntilExpiration",
    "variableName": "daysUntilExpiration",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "daysWaitingSince",
    "variableName": "daysWaitingSince",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "includePending",
    "variableName": "includePending",
    "type": "Boolean"
  },
  {
    "kind": "Variable",
    "name": "justMine",
    "variableName": "justMine",
    "type": "Boolean"
  },
  {
    "kind": "Variable",
    "name": "q",
    "variableName": "searchQuery",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "reviewType",
    "variableName": "reviewType",
    "type": "ReviewType"
  },
  {
    "kind": "Variable",
    "name": "sort",
    "variableName": "sort",
    "type": "String"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first",
    "type": "Int"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalCount",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "pk",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v6 = [
  v5
],
v7 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "category",
  "storageKey": null,
  "args": null,
  "concreteType": "CategoryType",
  "plural": false,
  "selections": v6
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "expirationDate",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "publishDate",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "restricted",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "preview",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "applicabilityType",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "cannotBulkReviewReason",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasPendingChild",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasAttachments",
  "args": null,
  "storageKey": null
},
v19 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isTemplate",
  "args": null,
  "storageKey": null
},
v20 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "revisedFromTemplateStatus",
  "args": null,
  "storageKey": null
},
v21 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "buckets",
  "args": null,
  "storageKey": null
},
v22 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "showBulkReview",
  "args": null,
  "storageKey": null
},
v23 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rssFeedUrl",
  "args": null,
  "storageKey": null
},
v24 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "counts",
  "storageKey": null,
  "args": null,
  "concreteType": "DueForReviewCounts",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "timeCount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "templateCount",
      "args": null,
      "storageKey": null
    }
  ]
},
v25 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "error",
  "storageKey": null,
  "args": null,
  "concreteType": "ErrorType",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "statusCode",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "message",
      "args": null,
      "storageKey": null
    }
  ]
},
v26 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v27 = [
  v5,
  v26
],
v28 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "category",
  "storageKey": null,
  "args": null,
  "concreteType": "CategoryType",
  "plural": false,
  "selections": v27
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "DocumentsForReviewQuery",
  "id": "449a09daa7361e619e4a8f1c722ab0b2",
  "text": null,
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "DocumentsForReviewQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "documentsToReview",
        "storageKey": null,
        "args": v1,
        "concreteType": "DocumentsToReviewType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "documents",
            "storageKey": null,
            "args": v2,
            "concreteType": "DocumentCountableConnection",
            "plural": false,
            "selections": [
              v3,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "DocumentCountableEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DocumentType",
                    "plural": false,
                    "selections": [
                      v4,
                      v5,
                      v7,
                      v8,
                      v9,
                      v10,
                      v11,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "bloc",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "BlocType",
                        "plural": false,
                        "selections": v6
                      },
                      v12,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "author",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "UserType",
                        "plural": false,
                        "selections": [
                          v13,
                          v14,
                          v15
                        ]
                      },
                      v16,
                      v17,
                      v18,
                      v19,
                      v20,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "documentsOfTemplate",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "DocumentsOfTemplateType",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "documents",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "DocumentType",
                            "plural": true,
                            "selections": [
                              v4,
                              v5,
                              v11,
                              v18,
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "bloc",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "BlocType",
                                "plural": false,
                                "selections": [
                                  v5,
                                  v4
                                ]
                              },
                              v12,
                              v7,
                              v8
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          v21,
          v22,
          v23,
          v24,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "categories",
            "storageKey": null,
            "args": null,
            "concreteType": "CategoryType",
            "plural": true,
            "selections": [
              v4,
              v5
            ]
          },
          v25
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "DocumentsForReviewQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "documentsToReview",
        "storageKey": null,
        "args": v1,
        "concreteType": "DocumentsToReviewType",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "documents",
            "storageKey": null,
            "args": v2,
            "concreteType": "DocumentCountableConnection",
            "plural": false,
            "selections": [
              v3,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "DocumentCountableEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "DocumentType",
                    "plural": false,
                    "selections": [
                      v4,
                      v5,
                      v28,
                      v8,
                      v9,
                      v10,
                      v11,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "bloc",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "BlocType",
                        "plural": false,
                        "selections": v27
                      },
                      v12,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "author",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "UserType",
                        "plural": false,
                        "selections": [
                          v13,
                          v14,
                          v15,
                          v26
                        ]
                      },
                      v16,
                      v17,
                      v18,
                      v19,
                      v20,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "documentsOfTemplate",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "DocumentsOfTemplateType",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "documents",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "DocumentType",
                            "plural": true,
                            "selections": [
                              v4,
                              v5,
                              v11,
                              v18,
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "bloc",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "BlocType",
                                "plural": false,
                                "selections": [
                                  v5,
                                  v4,
                                  v26
                                ]
                              },
                              v12,
                              v28,
                              v8,
                              v26
                            ]
                          }
                        ]
                      },
                      v26
                    ]
                  }
                ]
              }
            ]
          },
          v21,
          v22,
          v23,
          v24,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "categories",
            "storageKey": null,
            "args": null,
            "concreteType": "CategoryType",
            "plural": true,
            "selections": [
              v4,
              v5,
              v26
            ]
          },
          v25
        ]
      }
    ]
  }
};
})();
node.text = "query DocumentsForReviewQuery(\n  $after: String\n  $first: Int\n  $sort: String\n  $justMine: Boolean\n  $searchQuery: String\n  $categories: [Int]\n  $daysUntilExpiration: Int\n  $daysWaitingSince: Int\n  $includePending: Boolean\n  $reviewType: ReviewType\n) {\n  documentsToReview(q: $searchQuery, justMine: $justMine, sort: $sort, categories: $categories, daysUntilExpiration: $daysUntilExpiration, daysWaitingSince: $daysWaitingSince, includePending: $includePending, reviewType: $reviewType) {\n    documents(after: $after, first: $first) {\n      totalCount\n      edges {\n        node {\n          pk\n          name\n          category {\n            name\n            id\n          }\n          expirationDate\n          publishDate\n          restricted\n          preview\n          bloc {\n            name\n            id\n          }\n          applicabilityType\n          author {\n            firstName\n            lastName\n            title\n            id\n          }\n          cannotBulkReviewReason\n          hasPendingChild\n          hasAttachments\n          isTemplate\n          revisedFromTemplateStatus\n          documentsOfTemplate {\n            documents {\n              pk\n              name\n              preview\n              hasAttachments\n              bloc {\n                name\n                pk\n                id\n              }\n              applicabilityType\n              category {\n                name\n                id\n              }\n              expirationDate\n              id\n            }\n          }\n          id\n        }\n      }\n    }\n    buckets\n    showBulkReview\n    rssFeedUrl\n    counts {\n      timeCount\n      templateCount\n    }\n    categories {\n      pk\n      name\n      id\n    }\n    error {\n      statusCode\n      message\n    }\n  }\n}\n";
(node/*: any*/).hash = '25ca12cc7932f224d9ade9103da7d243';
module.exports = node;
