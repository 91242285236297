import VisuallyHidden from "@reach/visually-hidden";
import graphql from "babel-plugin-relay/macro";
import { Box, Flex } from "grid-styled";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer } from "react-relay";
import system from "system-components";

import { StyledFontAwesomeIcon } from "pstat-anywhere/components/partials/icons";
import { StatusBarItem } from "pstat-anywhere/components/view/StatusBar";
import { parseDocumentStatusToTranslationKey } from "pstat-anywhere/utils/document";
import { getRootUrl } from "pstat-anywhere/utils/urls";
import { Badge, RouterLinkedBadge } from "pstat-design-system/Badge";
import { FocusLink } from "pstat-design-system/Link";
import Modal from "pstat-design-system/modal";
import { Text } from "pstat-design-system/text";

const Info = ({ objectLabel, startOpen, children }) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(startOpen);

  return (
    <StatusBarItem>
      <FocusLink onActivation={() => setIsOpen(!isOpen)}>
        <StyledFontAwesomeIcon icon={["fal", "info-circle"]} size="lg" />
        <Text color="secondary.0">{t("view.statusBar.info")}</Text>
      </FocusLink>
      <InfoModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        objectLabel={objectLabel}
      >
        {children}
      </InfoModal>
    </StatusBarItem>
  );
};

export default Info;

const InfoModal = ({ isOpen, onClose, objectLabel, children }) => {
  const { t } = useTranslation();
  return (
    <Modal
      contentLabel={t("view.infoModal.contentLabel", { objectLabel })}
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <Box p={4}>{children}</Box>
    </Modal>
  );
};

export const Item = system({
  is: Box,
  mr: 4,
  mb: 4
});

export const StatusAndPkSection = ({ pk, status }) => (
  <Flex wrap="wrap">
    <PkDisplay pk={pk} />
    <ModalPolicyStatus status={status} />
  </Flex>
);

const ModalPolicyStatus = ({ status }) => {
  const { t } = useTranslation();
  const formattedStatus = t(parseDocumentStatusToTranslationKey(status));
  return (
    <Item>
      <VisuallyHidden>
        {t("view.infoModal.modalPolicyStatus.visuallyHidden", {
          formattedStatus
        })}
      </VisuallyHidden>
      <Text display="block" aria-hidden="true">
        {t("view.infoModal.modalPolicyStatus.title")}
      </Text>
      <Badge mx={0} aria-hidden="true">
        {formattedStatus}
      </Badge>
    </Item>
  );
};

const PkDisplay = ({ pk }) => {
  const { t } = useTranslation();
  return (
    <Item>
      <VisuallyHidden>
        {t("view.infoModal.pkDisplay.visuallyHidden", { pk })}
      </VisuallyHidden>
      <Text display="block" aria-hidden="true">
        {t("view.infoModal.pkDisplay.title")}
      </Text>
      <Badge mx={0} aria-hidden="true">
        {pk}
      </Badge>
    </Item>
  );
};

export const DateDisplay = ({ label, value }) => {
  const { t } = useTranslation();
  let displayValue = value;
  let screenReaderFullDate = value;
  if (value === null) {
    displayValue = "-";
    screenReaderFullDate = t("view.infoModal.dateDisplay.notApplicable");
  }
  return (
    <Item>
      <VisuallyHidden>{`${label}, ${screenReaderFullDate}`}</VisuallyHidden>
      <Text display="block" color="nav.25" aria-hidden="true">
        {label}
      </Text>
      <Text aria-hidden="true">{displayValue}</Text>
    </Item>
  );
};

export const KeyValuePair = props => (
  <Item>
    <Text ml={1} color="nav.25">
      {props.label}
    </Text>
    {props.children}
  </Item>
);

export const SectionDivider = system({
  borderTop: 1,
  borderColor: "nav.80",
  mb: 4
});

export const AuthorContainer = ({ documentSettings, labels, children }) => (
  <Fragment>
    {documentSettings.authorShow && (
      <KeyValuePair label={labels.authorLabel}>
        <div>{children}</div>
      </KeyValuePair>
    )}
  </Fragment>
);

export const CategoryContainer = ({ documentSettings, labels, children }) => (
  <Fragment>
    {documentSettings.departmentShow && (
      <KeyValuePair label={labels.categoryLabel}>
        <div>{children}</div>
      </KeyValuePair>
    )}
  </Fragment>
);

export const ReferencesContainer = ({
  references,
  documentSettings,
  labels,
  children
}) => (
  <Fragment>
    {documentSettings.referencesShow && (
      <Fragment>
        {references?.length > 0 && (
          <KeyValuePair label={labels.referencesLabel}>
            <div>{children}</div>
          </KeyValuePair>
        )}
      </Fragment>
    )}
  </Fragment>
);

export const ReferencesLinkBadge = ({ pk, text }) => {
  const pathname = `${getRootUrl()}/search`;
  const query = {
    reference: pk
  };
  const to = {
    pathname,
    query
  };
  return (
    <RouterLinkedBadge display="inline-block" to={to}>
      {text}
    </RouterLinkedBadge>
  );
};

export const References = ({ references, renderReference }) => {
  return references.map((tag, index) => {
    const isLastReference = references.length - 1 === index;
    return renderReference(tag.pk, tag.name, isLastReference);
  });
};

export const ReferencesFragmentContainer = createFragmentContainer(
  References,
  graphql`
    fragment Info_references on TagType @relay(plural: true) {
      pk
      name
    }
  `
);

export const ApprovedDate = ({ documentSettings, labels, date }) => (
  <Fragment>
    {documentSettings.approvedDateShow && (
      <DateDisplay label={labels.approvedDateLabel} value={date} />
    )}
  </Fragment>
);

export const RevisionDate = ({ documentSettings, labels, date }) => (
  <Fragment>
    {documentSettings.revisionDateShow && (
      <DateDisplay label={labels.revisionDateLabel} value={date} />
    )}
  </Fragment>
);
