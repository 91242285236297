import graphql from "babel-plugin-relay/macro";

const DocumentsForReviewQuery = graphql`
  query DocumentsForReviewQuery(
    $after: String
    $first: Int
    $sort: String
    $justMine: Boolean
    $searchQuery: String
    $categories: [Int]
    $daysUntilExpiration: Int
    $daysWaitingSince: Int
    $includePending: Boolean
    $reviewType: ReviewType
  ) {
    documentsToReview(
      q: $searchQuery
      justMine: $justMine
      sort: $sort
      categories: $categories
      daysUntilExpiration: $daysUntilExpiration
      daysWaitingSince: $daysWaitingSince
      includePending: $includePending
      reviewType: $reviewType
    ) {
      documents(after: $after, first: $first) {
        totalCount
        edges {
          node {
            pk
            name
            category {
              name
            }
            expirationDate
            publishDate
            restricted
            preview
            bloc {
              name
            }
            applicabilityType
            author {
              firstName
              lastName
              title
            }
            cannotBulkReviewReason
            hasPendingChild
            hasAttachments
            isTemplate
            revisedFromTemplateStatus
            documentsOfTemplate {
              documents {
                pk
                name
                preview
                hasAttachments
                bloc {
                  name
                  pk
                }
                applicabilityType
                category {
                  name
                }
                expirationDate
              }
            }
          }
        }
      }
      buckets
      showBulkReview
      rssFeedUrl
      counts {
        timeCount
        templateCount
      }
      categories {
        pk
        name
      }
      error {
        statusCode
        message
      }
    }
  }
`;

export default DocumentsForReviewQuery;
